<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Заведения</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-2">
      <v-col cols="10">
        <v-text-field v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Поиск"
                      dense
                      outlined
                      hide-details
                      rounded
                      @change="fetchPage"/>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="create" dark rounded
               color="primary">
          <span class="font-weight-bold hidden-xs-only">Создать</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="mt-2 hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="caption">Изображение</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="caption">Название</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center">
        <span class="caption">Адрес</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-end">
        <span class="caption pr-16">Телефон</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-space-around">
        <span class="caption">Скрыт</span>
        <span class="caption">Радиус</span>
        <span class="caption">Попул.</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="caption">Редактировать</span>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'px-2' : ''" no-gutters class="grey lighten-4 mb-1 d-flex align-center py-1"
           v-for="(item, index) in list" :key="item.id">
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Фото / Лого:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
             class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around">
        <v-avatar :class="$vuetify.breakpoint.xs ? 'mr-2' : ''" v-if="item.image" size="48px">
          <img :src="item.image" @click="$refs.image[index].click()" alt="Store image">
        </v-avatar>
        <v-avatar :class="$vuetify.breakpoint.xs ? 'mr-2' : ''" v-else size="48px">
          <img alt src="/12.jpg" @click="$refs.image[index].click()">
        </v-avatar>
        <div v-show="false">
          <input type="file" id="image"
                 ref="image" accept="image/*" @change="handleImageUpload(index, item)"/>
        </div>
        <v-avatar v-if="item.logo" size="48px">
          <img :src="item.logo" @click="$refs.logo[index].click()" alt="Store logo">
        </v-avatar>
        <v-avatar v-else size="48px">
          <img alt src="/12.jpg" @click="$refs.logo[index].click()">
        </v-avatar>
        <div v-show="false">
          <input type="file" id="logo"
                 ref="logo" accept="image/*" @change="handleLogoUpload(index, item)"/>
        </div>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Заведение:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
             class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start">
        <v-badge
            left
            color="primary"
            :content=item.commission>
          <span class="text-body-2 pl-1">{{ item.name }}</span>
        </v-badge>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Адрес:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
             class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start">
        <span :class="$vuetify.breakpoint.xs ? 'text-end' : ''" class="text-body-2">{{ item.address }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Тел.:</span>
      </v-col>
      <v-tooltip top>
        <template v-slot:activator="{ attrs, on }">
          <v-col v-bind="attrs" v-on="on" cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
                 class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center text-body-2 text-no-wrap">
            {{ item.phone | normalizePhone | minimizePhone }}
          </v-col>
        </template>
        {{ item.phone | normalizePhone }}
      </v-tooltip>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-space-around d-flex align-center">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Скрыт:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-4 col-md-4 col-sm-4 row no-gutters d-flex align-center justify-end justify-lg-center justify-md-center justify-sm-center">
          <v-checkbox v-model="item.hidden"
                      class="ma-0 pa-0"
                      hide-details
                      @change="save(item)"/>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Радиус:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-4 col-md-4 col-sm-4 row no-gutters d-flex align-center justify-end justify-lg-center justify-md-center justify-sm-center">
          <span class="text-body-2">{{ item.radius }} км.</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Популярный:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
               class="col col-lg-4 col-md-4 col-sm-4 row no-gutters d-flex align-center justify-end justify-lg-center justify-md-center justify-sm-center">
          <v-checkbox v-model="item.popular"
                      class="ma-0 pa-0"
                      hide-details
                      @change="save(item)"/>
        </v-col>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Редактировать:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
             class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center">
        <v-btn x-small fab @click="edit(item)">
          <v-icon color="primary">mdi-pencil</v-icon>
        </v-btn>
        <v-btn :class="$vuetify.breakpoint.xs ? 'mx-3' : ''" x-small fab @click="duplicate(item)">
          <v-icon v-if="role === 'ADMIN'" color="blue">mdi-content-copy</v-icon>
        </v-btn>
        <v-btn x-small fab @click="del(item)">
          <v-icon color="error" v-if="role === 'ADMIN'">mdi-delete-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-pagination
        v-model="currentPage"
        :length="paginationCount"
        total-visible="9"
        @input="fetchStores">
    </v-pagination>
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import format from "date-fns/format";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      search: "",
      count: 0,
      list: [],
      tags: [],
      currentPage: 1,
      limit: 10
    }
  },

  created() {
    let search = localStorage.getItem('search')
    if (search) {
      this.search = search
    }
    this.fetchTags()
    this.fetchStores()
  },

  computed: {
    role() {
      return this.$store.getters["auth/role"]
    },

    paginationCount() {
      if (!this.list || !this.count) {
        return 0
      }
      return Math.ceil(this.count / this.limit)
    }
  },

  methods: {
    fetchPage() {
      localStorage.setItem("search", this.search)
      this.currentPage = 1;
      this.fetchStores()
    },

    fetchStores() {
      let offset = (this.currentPage - 1) * this.limit
      let payload = {offset: offset, limit: this.limit}
      if (this.search && this.search.length > 0) {
        payload.search = this.search
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&')
      axios.send({
        url: "/store?" + queryString,
        method: "GET"
      }).then(resp => {
        this.count = resp.data.count
        this.list = resp.data.list
      });
    },

    fetchTags() {
      axios.send({
        url: "/tags",
        method: "GET"
      }).then(resp => {
        this.tags = resp.data
      })
    },

    del(item) {
      this.$confirm(
          "Вы точно хотите удалить заведение",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/store",
          method: "DELETE",
          data: item
        }).then(() => {
          this.fetchStores()
        });
      });
    },

    save(store) {
      console.log(store)
      axios.send({
        url: "/store",
        method: "POST",
        data: store
      }).then((resp) => {
        store = resp.data
      });
    },

    duplicate(item) {
      this.$confirm(
          "Создать копию заведения ?",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/store/duplicate/" + item.id,
          method: "POST"
        }).then(() => {
          this.fetchStores()
        })
      })
    },

    handleImageUpload(index, item) {
      let file = this.$refs.image[index].files[0]
      let formData = new FormData();
      formData.append("image", file)

      axios.send({
        url: "/store/" + item.id + "/image",
        method: "POST",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(resp => {
        item.image = resp.data;
      });
    },

    handleLogoUpload(index, item) {
      let file = this.$refs.logo[index].files[0]
      let formData = new FormData();
      formData.append("image", file)

      axios.send({
        url: "/store/" + item.id + "/logo",
        method: "POST",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(resp => {
        item.logo = resp.data
      })
    },

    create() {
      this.$router.push({name: 'editStores', params: {id: uuid.Nil}})
    },

    edit(item) {
      this.$router.push({name: 'editStores', params: {id: item.id}})
    },

    route(item) {
      this.$router.push({name: 'items', params: {id: item.id}})
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",")

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    },
    minimizePhone: (p) => {
      if (!p) {
        return ""
      }
      if (p.length > 17) {
        return p.substr(0, 17)
      }
      return p
    }
  }
}
</script>
